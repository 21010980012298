import { useState } from "react";
import "./AppHeader.less";
import { Layout, Menu } from "antd";
import NavBar from "./NavBar/NavBar";
import SideBar from "component/AppHeader/SideBar/SideBar";
import {
  FieldTimeOutlined,
  HistoryOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  SettingOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AppFooter } from "component/AppFooter/AppFooter";
import { $HeightAdjustmentSpacing } from "styles/global";

const TopicMenu = ({ topics, selectedKey, changeSelectedKey }: any) => {
  const styledTopics: any = [];
  topics.forEach((topic: any, index: any) =>
    styledTopics.push(
      <Menu.Item
        icon={topic.icon}
        key={index}
        title={topic.path}
        onClick={changeSelectedKey}
      >
        {topic.label}
      </Menu.Item>
    )
  );

  return (
    <Menu mode="inline" selectedKeys={[selectedKey]}>
      {styledTopics}
    </Menu>
  );
};

export const AppHeader = (props: any) => {
  const navigate = useNavigate();

  const topics = [
    { path: "home", label: "Home", icon: <HomeOutlined /> },
    { path: "meeting", label: "Meetings", icon: <VideoCameraOutlined /> },
    { path: "time-rate", label: "Timing & Rate", icon: <FieldTimeOutlined /> },
    { path: "history", label: "Consult History", icon: <HistoryOutlined /> },
    { path: "setting", label: "Setting", icon: <SettingOutlined /> },
    { path: "about", label: "About", icon: <InfoCircleOutlined /> },
    { path: "consult", label: "Consult", icon: <ProfileOutlined /> },
  ];
  // const [setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const changeSelectedKey = (event: any, f: any) => {
    const key = event.key;
    setSelectedKey(key);

    navigate("/" + event.item.props.title);
    // setContentIndex(+key);
  };
  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );
  return (
    <div>
      <NavBar menu={Menu} />
      <Layout
        className="main-routes-container"
        style={{ marginTop: $HeightAdjustmentSpacing + "px" }}
      >
        <SideBar menu={Menu} />
        <Layout.Content className="children-router">
          {props.children}
        </Layout.Content>
      </Layout>
      <AppFooter />
    </div>
  );
};
