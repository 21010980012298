import { Dropdown, Avatar, MenuProps } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const ProfileDropdown = () => {
  // Define the handler function for the menu click event
  const handleMenuClick = (e: any) => {
    // Perform different actions based on the menu item key
    switch (e.key) {
      case "profile":
        // Navigate to the profile page
        break;
      case "settings":
        // Navigate to the settings page
        break;
      case "logout":
        // Logout the user
        break;
      default:
        // Do nothing
        break;
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Profile",
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: "Setting",
      icon: <SettingOutlined />,
    },
    {
      key: "3",
      label: "Logout",
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];
  return (
    // Wrap the avatar component in the dropdown component
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      onOpenChange={handleMenuClick}
    >
      <div>
        <Avatar icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default ProfileDropdown;
