import { Layout } from "antd";
import "./styles.css";
import useSize from "styles/hook/useSize";
import { $HeightAdjustmentSpacing } from "styles/global";

const SideBar = ({ menu }: any) => {
  // const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const usesize = useSize();
  return (
    <Layout.Sider
      style={{ height: usesize[0] - $HeightAdjustmentSpacing }}
      className="app-sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
    >
      {menu}
    </Layout.Sider>
  );
};

export default SideBar;
