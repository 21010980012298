import { Loader } from "component/Loader/Loader";
import "./home.less";

const Home = () => {
  return (
    <div className="home-container">
      <Loader />
    </div>
  );
};

export default Home;
